<template>
    <div>
        <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
        <div class="order-xl-1">
            <div class="card">
                <div class="card-header">
                    <b-row align-v="center" slot="header" >
                        <b-col cols="8">
                            <h2 class="mb-0"><i class="fas fa-wrench"></i> เพิ่มเทมเพลต </h2>
                        </b-col>
                    </b-row>
                </div>
                <div class="card-body">
                    <div class="card custom-card">
                        <div class="card-body">
                            <div>
                                <label class="form-label">ชื่อเทมเพลต</label>
                                <base-input name="name" placeholder="รหัสการสั่งซื้อ" v-model="item.name"></base-input>
                            </div>
                            <b-tabs pills card vertical>
                                <b-tab title="เทมเพลตสำหรับลูกค้า" active>
                                    <b-card-text>
                                        <b-form-textarea
                                            id="textarea-rows"
                                            v-model="item.copy_customer"
                                            placeholder="เทมเพลตสำหรับลูกค้า"
                                            rows="30"
                                        ></b-form-textarea>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="เทมเพลตสำหรับซัพพลายเออร์">
                                    <b-card-text>
                                        <b-form-textarea
                                            id="textarea-rows"
                                            v-model="item.copy_supplier"
                                            placeholder="เทมเพลตสำหรับซัพพลายเออร์"
                                            rows="30"
                                        ></b-form-textarea>
                                    </b-card-text>
                                </b-tab>

                                <b-tab title="เทมเพลตสำหรับทวนออเดอร์">
                                    <b-card-text>
                                        <b-form-textarea  id="textarea-rows"
                                            v-model="item.copy_order"
                                        placeholder="เทมเพลตสำหรับทวนออเดอร์"
                                        rows="30"
                                        ></b-form-textarea>
                                    </b-card-text>
                                </b-tab>
                                <b-tab title="Email A">
                                    <!-- Old
                                    <b-card-text>
                                        <b-form-textarea  id="textarea-rows"
                                            v-model="item.email_a"
                                        placeholder=""
                                        rows="30"
                                        ></b-form-textarea>
                                    </b-card-text> -->
                                    <!-- Add -->
                                    <quill-editor   :content="item.email_a"
                                                    :options="editorOption"
                                                    @change="onEditorChange($event)" />
                                </b-tab>
                                <b-tab title="Email B">
                                    <!-- Old
                                    <b-card-text>
                                        <b-form-textarea  id="textarea-rows"
                                            v-model="item.email_b"
                                        placeholder=""
                                        rows="30"
                                        ></b-form-textarea>
                                    </b-card-text> -->
                                    <!-- Add -->
                                    <quill-editor   :content="item.email_b"
                                                    :options="editorOptionEmailB"
                                                    @change="onEditorChangeEmailB($event)" />
                                </b-tab>
                                <b-tab title="Email C">
                                    <!-- Old
                                    <b-card-text>
                                        <b-form-textarea  id="textarea-rows"
                                            v-model="item.email_c"
                                        placeholder=""
                                        rows="30"
                                        ></b-form-textarea>
                                    </b-card-text> -->
                                    <!-- Add -->
                                    <quill-editor   :content="item.email_c"
                                                    :options="editorOptionEmailC"
                                                    @change="onEditorChangeEmailC($event)" />
                                </b-tab>
                                
                            </b-tabs>
                             <div class="float-right">
                                <b-button @click="editHandler" size="md" text="Button" variant="primary" class="style_btn" style="width: 110px;">บันทึกใหม่</b-button>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'template-edit-index',
    data () {
        return {
            permission:{
                appSlug: 'template',
                actionData: {}
            },
            item: {},
            id: '',
            // Add
            editorOption: {},
            editorOptionEmailB: {},
            editorOptionEmailC: {},
        }
    },
    methods: {
       
        async editHandler () {
            const result = await this.HttpServices.postData("/ab_template/update", this.item);
            if(result&&result.status.code=="200"){
                this.AlertUtils.alertCallback('success', `บันทึกสำเร็จ`, ()=>{
                    this.$router.push('/template');
                });
                this.$emit("successFuction", "");
            }else{
                this.AlertUtils.alert('warning', result.status.message);
            }
        },
        async getItem(){
            const result = await this.HttpServices.getData(`/ab_template/get/${this.id}`);
            if(result&&result.status.code=="200"){
                this.item = result.data;
            }else{
                this.AlertUtils.alertCallback('warning', `ไม่พบข้อมูล`, ()=>{
                this.$router.push('/template');
                });
            }
        },
        // Add
        onEditorChange({ quill, html, text }) {
            console.log('onEditorChange ', quill, html, text)
            this.item.email_a = html
        },
        onEditorChangeEmailB({ quill, html, text }) {
            this.item.email_b = html
        },
        onEditorChangeEmailC({ quill, html, text }) {
            this.item.email_c = html
        },
    },
    async mounted() {
        await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
        this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
        this.id = await this.SessionStorageUtils.getSession("page-template");
        await this.getItem();
    }
};
</script>
<style scoped>
</style>
